<template lang="pug">
page.ea-vgas(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Header
  base-header(
    slot='header',
    :showHeaderLinks='false',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )

  //- Content
  template(slot='content')
    //- Video section
    //- page-section.ea-vgas-section.ea-vgas-section--video(:fullWidth='true', align='center')
    //-   template(slot='content')
    //-     surf-video(
    //-       :src='videoSrc',
    //-       :auto-play='true',
    //-       :loop='true',
    //-       :cover-mode='true',
    //-       :show-mute-button='true',
    //-       :ga-event-category='gaEventCategory',
    //-       :ga-event-label='gaEventLabel'
    //-     )
    //-     i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpToMain')

    //- Banner - when needed
    page-section.ea-vgas-section.ea-vgas-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
        .banner-img-bg

    //- Main
    #main
      page-section.ea-vgas-section.ea-vgas-section--enter.page-pt-md-alt.page-pb-md(align='center')
        template(slot='content')
          h1.page-mb-md
            template(v-if='isEnded') Thank you for participating.<br />Select your region to continue <span class='text-nowrap'>and view winners.</span>
            template(v-else) Over 50 giveaways are <span class='text-nowrap'>waiting for you.</span><br />Select your region <span class='text-nowrap'>to continue.</span>

          //- Countdown
          .page-mt-md-alt.fs-sm-alt(v-if='!isEnded && !!baseGiveaway')
            countdown(:giveaway='baseGiveaway', :is-active='isActive')

          //- Region buttons
          .page-mt-md-alt
            b-btn.mx-2.mb-3(
              v-for='region in regions',
              :key='region.id',
              variant='ea-vgas-white',
              :aria-label='region.ariaLabel',
              :to='{ name: `ea_vgas_${region.id}` }',
              @click='trackEvent(`to_region__${region.id}`)'
            )
              .copy {{ region.label }}
</template>
<script>
import SurfVideo from '@/components/SurfVideo'
import eaVgasPageMixin from './ea-vgas-page-mixin'

export default {
  name: 'EaVGAs',
  mixins: [eaVgasPageMixin],
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways',
      titleTemplate: '%s | Surf Giveaways'
    }
  },
  components: {
    SurfVideo
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
